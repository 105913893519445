import { Injectable } from "@angular/core";
import { RestApiService } from "../rest-api/rest-api.service";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})

export class CommonService {
  constructor(
    public RestApiService: RestApiService,
    public dialog: MatDialog
  ) {
    if (localStorage.getItem('warningPopup') === null) {
      localStorage.setItem('warningPopup', 'false');
    }
  }
  invoiceIdSubject = new Subject<any>();

  sendInvoiceId(invoiceId: any) {
    this.invoiceIdSubject.next(invoiceId);
  }

  getProcessorDetails(value) {
    return this.RestApiService.get_API_New(
      "Generic/fetchProcessorList", value
    );
  }

  getItemizationStatusDetails(value) {
    return this.RestApiService.get_API_New(
      "Generic/ItemizationStatus", value
    );
  }

  getApproverDetails(value) {
    return this.RestApiService.get_API_New(
      "Generic/fetchApproverList", value
    );
  }

  getDeductionTypeDetails(value) {
    if (value && value === 'memoChat') {
      return this.RestApiService.get_API_New(
        "Generic/fetchDeductionsTypeList?all=true", null
      );
    } else {
      return this.RestApiService.get_API_New(
        "Generic/fetchDeductionsTypeList", value
      );
    }
  }

  getRetailerTypeDetails() {
    return this.RestApiService.get_API_New(
      "index/retailerConfigs", null
    );
  }

  getProcessingStatusDetails(value) {
    return this.RestApiService.get_API_New(
      "Generic/fetchProcessingStatusList", value
    );
  }

  getDisputeStatusDetails(value) {
    return this.RestApiService.get_API_New(
      "Generic/fetchDisputeStatusList", value
    );
  }

  getCustomerERPIdDetails(value) {
    return
    // return this.RestApiService.get_API_New("Generic/fetchCustomerErpIdList",value);
  }

  fileDownLoad(value) {
    return this.RestApiService.file_DownLoad_Api(
      "Generic/downloadfile", value
    );
  }

  invoiceDownload(value, table) {
    return this.RestApiService.fileDownLoad_Api_GZip(
      "Generic/downloadDocument/" + table, value
    );
  }

  fileUpload(value) {
    return this.RestApiService.file_Upload_Api("Generic/uploadfile", value);
  }

  userList(type: any) {
    return this.RestApiService.get_API_New(
      `Generic/fetchUsersList?type=${type}`, {}
    );
  }

  getCustomerDetails(value) {
    return this.RestApiService.get_API_New(
      "Generic/fetchCustomersList", value
    );
  }

  getProductConfigDetails(value, type: any) {
    let query_param = '';
    if (type) {
      type.forEach(element => {
        query_param += 'type=' + element;
      });
      query_param = '?' + query_param
    }
    return this.RestApiService.get_API_New(
      "Generic/fetchProductConfigList" + query_param, value
    );
  }

  getCreditLineItemdetails(value) {
    return this.RestApiService.get_API_New(
      "Validations/fetchCreditLineItems", value
    );
  }

  deleteBackupDocument(data) {
    return this.RestApiService.post_Api(
      "Generic/deleteInvoiceDocument", data
    )
  }

  getDocumentDetails(data?) {
    return this.RestApiService.get_API_New(
      `arDocuments/fetchDocuments`, {})
  }

  docUpload(value) {
    return this.RestApiService.file_Upload_Api(
      "arDocuments/uploadDocument", value
    );
  }

  ARDocUpload(value) {
    return this.RestApiService.file_Upload_Api_Post(
      "arDocuments/createARDocument", value);
  }

  PromotionsUpload(value) {
    return this.RestApiService.file_Upload_Api_Post("promotions/create", value);
  }

  getdebitDetails(value) {
    return this.RestApiService.get_API_New("debitmemo/fetchDebitMemo", value);
  }

  updateDebitMemo(value) {
    return this.RestApiService.post_Api("debitmemo/update", value);
  }

  getUserInfo(value) {
    return this.RestApiService.get_API_New(
      "index/fetchUserDetails", value
    );
  }

  itemizeDebitMemo(value) {
    return this.RestApiService.post_Api("debitmemo/itemize", value);
  }

  masterReport(value) {
    return this.RestApiService.post_Api("reports/master_table" + "?download=" + value.download, value);
  }

  masterReport_download(value) {
    return this.RestApiService.file_DownLoad_Api("reports/master_table" + "?download=" + value.download, value);
  }

  generateDocs1(value) {
    return this.RestApiService.file_DownLoad_Api("reports/master_credit_report",
      value);
  }

  getDeductionsByCustomer(data) {
    return this.RestApiService.post_Api("reports/fetchReportingTable", data);
  }

  changeDocumentType(data) {
    return this.RestApiService.post_Api("documents/change_document_type", data);
  }

  getCustomerDedHeaders(data) {
    return this.RestApiService.get_API_New("reports/fetchReportsHeader", data);
  }

  //All Documents
  getAllDocs(requestUrl, data) {
    return this.RestApiService.get_API_New("documents/all_docs/v2" + requestUrl,
      data);
  }

  fetchAllDocuments(data) {
    return this.RestApiService.get_API_New(`documents/fetchAllDocuments/${data.doc_type}/${data.id}`, {});
  }

  fetchDocumentType(data) {
    return this.RestApiService.get_API_New("documents/fetchDocumentType", data);
  }

  getPaymentStatusList(value) {
    return this.RestApiService.get_API_New("Generic/PaymentStatus", value);
  }

  getRemittanceList(value) {
    return this.RestApiService.get_API_New("Generic/getUnlinkedRemittances	",
      value);
  }

  linkPayments(data) {
    return this.RestApiService.post_Api("Generic/linkPaymentsRemittances", data);
  }

  getInvoiceMessages() {
    return this.RestApiService.post_Api(`Generic/fetchAllNotificationMessage`,
      {});
  }

  clearNotification(data) {
    return this.RestApiService.patch_Api(`Generic/clearNotification?id=${data}`, {});
  }

  getInvoiceProcessingStatus(invoiceId: any) {
    return this.RestApiService.get_API_New(`Generic/getdetails?table_name=invoices&column_name=processing_status&ids=${invoiceId}`, {});
  }

  addNotificationMessage(data) {
    return this.RestApiService.post_Api('Generic/addNotificationMessage', data);
  }

  searchUser(txt: any) {
    return this.RestApiService.get_API_New(`Generic/fetchUsersList?search=${txt}&type=0`, {});
  }

  getCustomerSubGroup(customerId) {
    return this.RestApiService.get_API_New(`Validations/fetchCustomerSubGroup?customer_id=${customerId}`, {});
  }

  splitRevert(data: any) {
    return this.RestApiService.post_Api('Validations/splitReverse', data);
  }

  getSingleInvoiceData(encodedUrl: any) {
    return this.RestApiService.post_Api(`Validations/fetchAllDeductions/v2?page=1&size=50&qs=${encodedUrl}`, { processing_status_filter: null });
  }

  getCustomersList() {
    return this.RestApiService.get_API_New(`Generic/fetchCustomersList`, {});
  }

  getRetailerClaimTypes(customerId) {
    return this.RestApiService.get_API_New(`Generic/RetailerClaimTypes?customer_id=${customerId}`, {});
  }

  getRetailerDocumentType(customerId) {
    return this.RestApiService.get_API_New(`Generic/RetailerDocumentType?customer_id=${customerId}`, {});
  }

  getPromotionDropdownData() {
    return this.RestApiService.get_API_New(`promotions/configurations?all_configs=false`, {});
  }
}